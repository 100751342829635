import { useEffect, useState } from "react";
import "../App.css";
import { WebsiteApi } from "../Config/API";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Fulllogo from "./img/logo2.png";
import MobileAppQR from "./img/MobileAppQr1.png";
import YouTubeLogo from "./img/YouTube-Logo.png";
import AndroidIcon from "./img/pngegg.png";
import parse from "html-react-parser";

function Contact() {
    const [Name, setName] = useState();
    const [Phone, setPhone] = useState(null);
    const [Email, setEmail] = useState(null);
    const [Message, setMessage] = useState(null);
    const [AboutUsData, setAboutUsData] = useState("");

    const AddData = (e) => {
        e.preventDefault();

        const data = {
            name: Name,
            phone: Phone,
            email: Email,
            message: Message
        }

        WebsiteApi.AddContactForm(data)
            .then((response) => {
                toast.success("we will Reach back Soon.", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: 0,
                    draggable: true
                });
                cleardata()
            })
            .catch((error) => { });
    }

    const cleardata = () => {
        setName("")
        setPhone("")
        setEmail("")
        setMessage("")
    }

    useEffect(() => {
        WebsiteApi.getAboutUsData().then((res) => {
            console.log(res);
            setAboutUsData(res.data.data.description);
        }).catch((err) => { });
    }, [])

    return (
        <section id="get-started about" class="get-started section-bg">
            <ToastContainer />
            <div class="container">
                <div class="section-header">
                    <h2>About Us</h2>
                </div>
                <div class="row justify-content-between">
                    <div class="col-lg-6">
                        <div class="content">
                            {parse(AboutUsData)}
                            {/* 
                            <h4>Interior design, architecture and construction</h4>
                            <p>We work worldwide</p>
                            <p>	Axis Designs consists of 3 unique brands, with its business scope covering all sectors of the world of interior design and architecture. By creating projects in our unique one-of-a-kind style, we aim to become one of the best international design. Our team of experts from a wide variety of areas make dreams of a luxurious and comfortable life come true.
                                The art of living in the style of Axis Designs can be described as the manifesto of individuality, ongoing creative search and modern view on heritage. We create a new quality of life by providing our clients with a sense of exclusivity for many years forward. From the beginning of the design project to the furniture production, we design a luxurious lifestyle, offering a unique method of working with the customer and high-quality service.
                                <br /> <br />  The unique concept of the group is based on three key elements, including inner growth, the principle of "interior as art" and diverse creative approach. We follow global trends, draw inspiration from the world of fashion and design, and expand the geography of our projects.<br />
                                <br />Our success defines our vision of modern luxury in a global perspective, the quality of services and the responsible creative approach of our team. It allows us to achieve new goals with every new project and become a part of the design of the future. </p> */}
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div className="formbox">
                            <form onSubmit={AddData} class="php-email-form">
                                <h3>Lets Discuss to Design</h3>
                                <p>Discussion of the project does not mean the beginning of work.Lets get in Touch.</p>
                                <div class="row gy-3" >
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" placeholder="Name" value={Name} onChange={(e) => setName(e.target.value)} required style={{ color: "white" }} />
                                    </div>
                                    <div class="col-md-12 ">
                                        <input type="email" class="form-control" placeholder="Email" value={Email} onChange={(e) => setEmail(e.target.value)} style={{ color: "white" }} />
                                    </div>
                                    <div class="col-md-12">
                                        <input type="number" class="form-control" placeholder="Phone" value={Phone} onChange={(e) => setPhone(e.target.value)} required style={{ color: "white" }} />
                                    </div>
                                    <div class="col-md-12">
                                        <textarea class="form-control" rows="6" placeholder="Message" value={Message} onChange={(e) => setMessage(e.target.value)} required style={{ color: "white" }}></textarea>
                                    </div>
                                    <div class="col-md-12 text-center">
                                        <button type="submit">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <center>
                <div class="container">
                    <div class="row justify-content-between gy-4">
                        <div className="col-sm-6">
                            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/LN0j9VVadMw?autoplay=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                        </div>
                        <div className="col-sm-6">
                            <div class="mobileapp">
                                <div class="qr-code">
                                    <img src={MobileAppQR} />
                                </div>
                                <div class="appname">Scan me<br /></div>
                                {/* <i class="bi bi-android"></i><br /> */}
                                <img src={AndroidIcon} style={{ width: 40, marginTop: 10, marginBottom: 10, height: 40 }} />

                                <br />
                                <a href="https://play.google.com/store/apps/details?id=com.design.axisdesign" target="_blank">
                                    <button class="downloadapp"><center>Click here to Download App  &#8594;</center></button>
                                </a>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </center>

        </section>
    )
}

export default Contact;
