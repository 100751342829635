import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { WebsiteApi } from "../Config/API";
import Footer from './footer';
import MobileAppQR from "./img/MobileAppQr.jpeg";
import AndroidIcon from "./img/pngegg.png";

import "../App.css";
import { ToastContainer, toast } from 'react-toastify';
import Contact from './contact';
function ContactUs() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();
    const [Name, setName] = useState();
    const [Phone, setPhone] = useState(null);
    const [Email, setEmail] = useState(null);
    const [Message, setMessage] = useState(null);

    const AddData = (e) => {
        e.preventDefault();

        const data = {
            name: Name,
            phone: Phone,
            email: Email,
            message: Message
        }

        WebsiteApi.AddContactForm(data)
            .then((response) => {
                toast.success("we will Reach back Soon.", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: 0,
                    draggable: true
                });
                cleardata()
            })
            .catch((error) => { });
    }

    const cleardata = () => {
        setName("")
        setPhone("")
        setEmail("")
        setMessage("")
    }
    return (
        <div style={{ backgroundColor: "#171717" }}>
            <div class="breadcrumbs d-flex align-items-center" style={{ background: "#000000" }}>
                <div class="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                    <h2>Contact Us</h2>
                    <ol>
                        <li><a onClick={() => { navigate('/'); }}>Home</a></li>
                        <li>Contact</li>
                    </ol>
                </div>
            </div>
            <section id="get-started about" class="get-started section-bg">
                <div class="row justify-content-between">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-4">
                        <div class="row">
                            <div class="col-md-6" data-aos="zoom-in-up">
                                <div class="info-box mt-4">
                                    <i class="bi bi-geo-alt" ></i>
                                    <h3>Address</h3>
                                    <p>
                                        <a href='https://www.google.com/maps/place/Axis+Designs/@23.250279,69.675189,16z/data=!4m6!3m5!1s0x3950e20237793103:0x9c3dd9a4f1ca70c7!8m2!3d23.2502793!4d69.6751891!16s%2Fg%2F11ddwzp1gn?hl=en' target={"_blank"}>
                                            202, 2nd floor, krushna chambers-B, Station Rd,
                                            Bhuj, Gujarat 370001
                                        </a>

                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6" data-aos="zoom-in-up">
                                <div class="info-box mt-4">
                                    <i class="bi bi-envelope"></i>
                                    <h3>Email Us</h3>
                                    <a href="mailto:contact@axisdesigns.co.in" target={"_blank"}><p>contact@axisdesigns.co.in</p></a>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="info-box mt-4" data-aos="zoom-in-up">
                                    <i class="bi bi-telephone"></i>
                                    <h3>Call Us</h3>
                                    <p>
                                        <a href="tel:+919825214066" target={"_blank"}>+91 98252 14066 </a>

                                        <br />

                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="info-box mt-4" data-aos="zoom-in-up">
                                    <i class="bi bi-clock"></i>
                                    <h3>Open Hours</h3>
                                    <p>
                                        Monday - Friday
                                        <br />
                                        10:30AM - 07:00PM
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-1"></div>

                    <div class="col-lg-3">
                        <div className="formbox">
                            <form onSubmit={AddData} class="php-email-form">
                                <h3>Lets Discuss to Design</h3>
                                <p>Discussion of the project does not mean the beginning of work.Lets get in Touch.</p>
                                <div class="row gy-3" >
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" placeholder="Name" value={Name} onChange={(e) => setName(e.target.value)} required style={{ color: "white" }} />
                                    </div>
                                    <div class="col-md-12 ">
                                        <input type="email" class="form-control" placeholder="Email" value={Email} onChange={(e) => setEmail(e.target.value)} style={{ color: "white" }} />
                                    </div>
                                    <div class="col-md-12">
                                        <input type="number" class="form-control" placeholder="Phone" value={Phone} onChange={(e) => setPhone(e.target.value)} required style={{ color: "white" }} />
                                    </div>
                                    <div class="col-md-12">
                                        <textarea class="form-control" rows="6" placeholder="Message" value={Message} onChange={(e) => setMessage(e.target.value)} required style={{ color: "white" }}></textarea>
                                    </div>
                                    <div class="col-md-12 text-center">
                                        <button type="submit">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-2"></div>

                </div>
            </section>
            <br />
            <br />
            <br />
            <Footer />
        </div>
    )
}

export default ContactUs;