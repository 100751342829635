import React from 'react'
import { Button } from 'react-bootstrap'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from "../components/img/logo.png";

function whatsapp() {
    return (
        <div >
            <FloatingWhatsApp phoneNumber="9825214066"
                // className='stickyDockWP'
                darkMode
                statusMessage='online'
                avatar={logo}
                allowEsc
                notificationSound
                accountName="Axis Design" />

        </div>
    )
}

export default whatsapp