import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { WebsiteApi } from "../Config/API";
import logo from "./img/logo2.png";

export default function Footer() {
    const [Services, setServices] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        GetData()
    }, [])
    const GetData = () => {
        WebsiteApi.AllServices()
            .then((response) => {
                setServices(response.data.data)
                console.log(Services)
            })
            .catch((error) => { });
    }
    return (
        <>
            {/* <footer id="footer" class="footer">

                <div class="footer-content position-relative">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-md-6">
                                <div class="footer-info">
                                    <img src={logo} style={{ width: "50%" }} />
                                    <p>
                                        <strong>Timing:</strong> 10:30 to 7:00 pm<br />
                                        <strong>Phone:</strong> <a href="tel:+919825214066">+91 98252 14066</a><br />
                                    </p>
                                    <div class="social-links d-flex mt-3">
                                        <a href="https://www.facebook.com/AxisDesignsBhuj?mibextid=LQQJ4d" class="d-flex align-items-center justify-content-center" target="_blank"><i class="bi bi-facebook"></i></a>
                                        <a href="https://instagram.com/axis.india?igshid=YmMyMTA2M2Y=" class="d-flex align-items-center justify-content-center" target="_blank"><i class="bi bi-instagram"></i></a>
                                        <a href="https://www.linkedin.com/in/axisdesigns/" class="d-flex align-items-center justify-content-center" target="_blank"><i class="bi bi-linkedin"></i></a>
                                        <a href="https://pin.it/6n7K8Gi" class="d-flex align-items-center justify-content-center" target="_blank"><i class="bi bi-pinterest"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><a href="#" >Home</a></li>
                                    <li><a onClick={() => { navigate('/contact-us'); }} style={{ cursor: "pointer" }}>Contact</a></li>
                                    <li><a onClick={() => { navigate('/services'); }} style={{ cursor: "pointer" }}>Services</a></li>
                                    <li><a onClick={() => { navigate('/projects'); }} style={{ cursor: "pointer" }}>Portfolio</a></li>
                                </ul>
                            </div>

                            <div class="col-lg-3 col-md-3 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    {Services.slice(0, 4).map((data) => {
                                        return (<li style={{ cursor: "pointer" }} onClick={() => { navigate('/service', { state: { id: data.id } }); }}><a >{data.title}</a></li>);
                                    })}
                                </ul>
                            </div>

                            <div class="col-lg-3 col-md-3 footer-links">
                                <center>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3665.802325332196!2d69.6751891!3d23.2502793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950e20237793103%3A0x9c3dd9a4f1ca70c7!2sAxis%20Designs!5e0!3m2!1sen!2sin!4v1676117120532!5m2!1sen!2sin"
                                        style={{ WebkitFilter: "grayscale(90%)", border: "0", width: "320px", height: "240px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </center>
                            </div>

                            <div class="footer-legal text-center position-relative">
                                <center>
                                    <div class="col-lg-6">
                                        <div class="row justify-content-center ">
                                            <div class="col-md-12 contact" style={{ fontSize: "18px" }}>
                                                <span>
                                                    <a href="tel:+919825214066">+91 98252 14066</a> &nbsp;|&nbsp; <a href="mailto:contact@axisdesigns.co.in" class="email">contact@axisdesigns.co.in</a>
                                                </span>
                                                <br></br>
                                            </div>
                                        </div>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="footer-legal text-center position-relative">
                    <div class="container">
                        <div class="copyright">
                            Copyright &copy; {(new Date().getFullYear())}. <strong><span>Axis Designs</span></strong>. All Rights Reserved
                        </div>
                        <div class="credits">
                        </div>
                    </div>
                </div>

            </footer> */}

            <footer id="footer">
                <div class="footer-top">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-7 col-md-6 ">

                                <div class="row ">
                                    <div class="col-lg-4 col-md-6 ">

                                        <div class="footer-info ">
                                            <div class=" align-self-center fcont ">
                                                <center>
                                                    <img src={logo} style={{ width: "100%" }} />
                                                </center>
                                            </div>
                                            <p>
                                                <strong>Timing:</strong> 10:30 to 7:00 pm<br />
                                                <strong>Phone:</strong> <a href="tel:+919825214066">+91 98252 14066</a><br />
                                            </p>
                                            <div class="social-links d-flex mt-3">
                                                <a href="https://www.facebook.com/AxisDesignsBhuj?mibextid=LQQJ4d" class="d-flex align-items-center justify-content-center" target="_blank"><i class="bi bi-facebook"></i></a>
                                                <a href="https://instagram.com/axis.india?igshid=YmMyMTA2M2Y=" class="d-flex align-items-center justify-content-center" target="_blank"><i class="bi bi-instagram"></i></a>
                                                <a href="https://www.linkedin.com/in/axisdesigns/" class="d-flex align-items-center justify-content-center" target="_blank"><i class="bi bi-linkedin"></i></a>
                                                <a href="https://pin.it/6n7K8Gi" class="d-flex align-items-center justify-content-center" target="_blank"><i class="bi bi-pinterest"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="align-self-center col-lg-4 footer-links ">
                                        <h4>Useful Links</h4>
                                        <ul class="">
                                            <li><i class="bi bi-arrow-right"></i><a onClick={() => { navigate('/'); }} style={{ cursor: "pointer" }} >Home</a></li>
                                            <li><i class="bi bi-arrow-right"></i><a onClick={() => { navigate('/contact-us'); }} style={{ cursor: "pointer" }}>Contact</a></li>
                                            <li><i class="bi bi-arrow-right"></i><a onClick={() => { navigate('/services'); }} style={{ cursor: "pointer" }}>Services</a></li>
                                            <li><i class="bi bi-arrow-right"></i><a onClick={() => { navigate('/projects'); }} style={{ cursor: "pointer" }}>Portfolio</a></li>
                                        </ul>
                                    </div>
                                    <div class=" align-self-center col-lg-4 footer-links">
                                        <h4>Our Services</h4>
                                        <ul>
                                            {Services.slice(0, 4).map((data) => {
                                                return (<li style={{ cursor: "pointer" }} onClick={() => { navigate('/service', { state: { id: data.id } }); }}><i class="bi bi-arrow-right"></i>{data.title}</li>);
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-5 col-md-6">
                                <center>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3665.802325332196!2d69.6751891!3d23.2502793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950e20237793103%3A0x9c3dd9a4f1ca70c7!2sAxis%20Designs!5e0!3m2!1sen!2sin!4v1676117120532!5m2!1sen!2sin"
                                        style={{ WebkitFilter: "grayscale(90%)", border: "0", width: "100%", height: "290px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </center>
                            </div>
                        </div>
                        <br />
                        <div class="footer-legal text-center position-relative">
                            <center>
                                <div class="col-lg-6">
                                    <div class="row justify-content-center ">
                                        <div class="col-md-12 contact" style={{ fontSize: "18px" }}>
                                            <span>
                                                <a href="tel:+919825214066">+91 98252 14066</a> &nbsp;|&nbsp; <a href="mailto:contact@axisdesigns.co.in" class="email">contact@axisdesigns.co.in</a>
                                            </span>
                                            <br></br>
                                        </div>
                                    </div>
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="copyright">
                        Copyright &copy; {(new Date().getFullYear())}. <strong><span>Axis Designs</span></strong>. All Rights Reserved
                    </div>
                    <div class="credits">
                    </div>
                </div>
            </footer>
        </>
    )
}