import axios from "axios";
class Api {
  static _axios = null;

  static instance() {
    if (this._axios == null) {
      this._axios = axios.create({
        baseURL: "https://backend.axisdesigns.co.in/api/",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
          secure: false
        },
      });
    }
    return this._axios;
  }
  static toJSON(data) {
    var object = {};
    data.forEach((value, key) => (object[key] = value));
    return object;
  }
}

export class WebsiteApi {
  static AllSlider() {
    return Api.instance().get("sliders")
  }
  static AllServices() {
    return Api.instance().get("services")
  }
  static FindServices(id) {
    return Api.instance().get("services/" + id)
  }
  static AllPortfolio() {
    return Api.instance().get("portfolios")
  }
  static AllPortfolioByServiceId(id) {
    return Api.instance().get("portfolios?service_id=" + id)
  }
  static PortfolioImagesById(id) {
    return Api.instance().get("portfolioimages?portfolio_id=" + id)
  }
  static AddContactForm(data) {
    return Api.instance().post("contacts", data)
  }
  static AllServiceCategory(id) {
    return Api.instance().get("categories?service_id=" + id)
  }
  static getAboutUsData() {
    return Api.instance().get("abouts")
  }

}