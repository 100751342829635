import React, { useEffect, useState } from 'react'
import { Modal, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import "../App.css";
import ImageGallery from 'react-image-gallery';
import { WebsiteApi } from "../Config/API";
import { ThreeCircles } from 'react-loader-spinner';
import Footer from './footer';

function AllProjects() {
    const [Portfolio, setPortfolio] = useState([])
    const [CategoriesName, setCategoriesName] = useState("");
    const [PortfolioImages, setPortfolioImages] = useState([]);

    useEffect(() => {
        GetData()
    }, []);

    const GetData = () => {
        WebsiteApi.AllPortfolio()
            .then((response) => {
                setPortfolio(response.data.data)
            })
            .catch((error) => { });
    }

    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const GetImageDataById = (id, categoryName) => {
        setPortfolioImages([]);
        setCategoriesName(categoryName);

        WebsiteApi.PortfolioImagesById(id)
            .then((response) => {
                setPortfolioImages([]);
                if (response.data.data.length !== 0) {

                    response.data.data.map((element) => {
                        // setCategoriesName(element.category.title);
                        PortfolioImages.push({
                            original: element.image,
                            thumbnail: element.image,
                        },);
                    });
                    setPortfolioImages([...PortfolioImages]);
                    console.log(PortfolioImages);
                    console.log(CategoriesName);

                    if (PortfolioImages.length !== 0) {
                        handleShow();
                    }
                }
            })
            .catch((error) => { });
    }

    if (Portfolio.length !== 0) {
        return (
            <div>
                <div class="breadcrumbs d-flex align-items-center" style={{ background: "#000000" }}>
                    <div class="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                        <h2>Portfolio</h2>
                        <ol>
                            <li><a onClick={() => { navigate('/'); }}>Home</a></li>
                            <li>Our Works</li>
                        </ol>

                    </div>
                </div>

                <section id="projects" class="projects">
                    <div class="container" data-aos="fade-up">


                        {/* <div class="section-header">
                            <h2>Our works</h2>
                        </div> */}

                        <div class="row gy-4" >
                            {Portfolio.map((data) => (
                                <>
                                    <div class="col-sm-4 " data-aos="fade-up" data-aos-delay="100" onClick={() => GetImageDataById(data.id, data.service.title)} style={{ cursor: "pointer" }}>
                                        <div class="card-item">
                                            <div className="childb1project">
                                                <p className="text">

                                                    <span className="title">{data.service.title}</span>
                                                    <p className="description">{data.description}</p>
                                                </p>

                                            </div>

                                            <div class="row-xl-5">
                                                <div class="card-bg" style={{ background: `url("${data.image}")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", width: "100%", }}></div>
                                            </div>
                                            <div class="card-body">
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                        <br />
                        <br />
                    </div>
                </section >
                <Modal
                    show={show}
                    onHide={() => { setShow(false); setPortfolioImages([]); }}
                    // size='xl'
                    fullscreen
                >
                    <Modal.Header
                        style={{ backgroundColor: "#171717", color: "white" }}
                        color='#fffs'
                        closeButton
                    >
                        <div class="container">
                            <div class="d-flex justify-content-center">
                                <div class="d-flex align-content-center "><h4>{CategoriesName}</h4></div>

                            </div>
                        </div>
                        <div style={{ fontSize: 30, cursor: "pointer" }} onClick={() => { handleClose(); setPortfolioImages([]); }}> <i class="bi bi-x"></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body
                        style={{ backgroundColor: "black" }}
                    >
                        <ImageGallery items={PortfolioImages} useTranslate3D={true} />
                    </Modal.Body>
                </Modal>
                <Footer />
            </div>
        )
    }
    return (
        <div className='loader'>
            <div className='center'>
                <ThreeCircles
                    height="100"
                    width="100"
                    color="#ffffff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor=""
                    middleCircleColor=""
                />
            </div>
        </div>
    )
}

export default AllProjects;