import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { WebsiteApi } from "../Config/API";
import { ThreeCircles } from 'react-loader-spinner';
import Footer from './footer';
import "../App.css";

function AllServices() {
    const [Services, setServices] = useState([])
    const [CategoriesName, setCategoriesName] = useState("");
    const [ServicesImages, setServicesImages] = useState([]);

    useEffect(() => {
        GetData()
    }, []);

    const GetData = () => {
        WebsiteApi.AllServices()
            .then((response) => {
                setServices(response.data.data)
            })
            .catch((error) => { });
    }

    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const GetImageDataById = (id, categoryName) => {
        setServicesImages([]);
        setCategoriesName(categoryName);

        WebsiteApi.ServicesImagesById(id)
            .then((response) => {
                setServicesImages([]);
                if (response.data.data.length !== 0) {

                    response.data.data.map((element) => {
                        ServicesImages.push({
                            original: element.image,
                            thumbnail: element.image,
                        },);
                    });
                    setServicesImages([...ServicesImages]);
                    console.log(ServicesImages);
                    console.log(CategoriesName);

                    if (ServicesImages.length !== 0) {
                        handleShow();
                    }
                }
            })
            .catch((error) => { });
    }

    if (Services.length !== 0) {
        return (
            <div>
                <div class="breadcrumbs d-flex align-items-center" style={{ background: "#000000" }}>
                    <div class="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                        <h2>Services</h2>
                        <ol>
                            <li><a onClick={() => { navigate('/'); }}>Home</a></li>
                            <li>Our Services</li>
                        </ol>
                    </div>
                </div>
                <section id="constructions" class="constructions">
                    <div class="container" data-aos="fade-up">
                        <div class="row gy-4" >
                            {Services.map((service) => (
                                <>
                                    <div class="col-sm-4" onClick={() => { navigate('/service', { state: { id: service.id } }); }}>
                                        <div class="card">
                                            <img class="card-img-top" src={service.image} alt="Card image cap" />
                                            <div class="card-body">
                                                <div className="childbb">
                                                    <h5 class="card-title">{service.title}</h5>
                                                </div>
                                                <p class="card-text">{service.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                        <br />
                        <br />
                    </div>
                </section >
                <Footer />
            </div>
        )
    }
    return (
        <div className='loader'>
            <div className='center'>
                <ThreeCircles
                    height="100"
                    width="100"
                    color="#ffffff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor=""
                    middleCircleColor=""
                />
            </div>
        </div>
    )
}

export default AllServices;