import { useEffect, useState } from "react";
import "../App.css";
import { WebsiteApi } from "../Config/API";
import logo from "./img/logo2.png";

function Hero({ sliders }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // GetData()
    const intervalId = setInterval(() => {
      if (currentIndex === sliders.length - 1) {
        setCurrentIndex(0);
      }
      else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 10000)

    return () => clearInterval(intervalId);
  }, [currentIndex])
  return (
    <section id="hero" class="hero">

      <div class="info d-flex align-items-center">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 text-center">
              <br />
              <br />
              <br />
              {/* <h2 data-aos="fade-down">Welcome to <span>UpConstruction</span></h2> */}
              <img src={logo} data-aos="fade-up" className="sidemenuimg1" style={{ width: "60%" }} />
              {/* <p data-aos="fade-up">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              <a data-aos="fade-up" data-aos-delay="200" href="#get-started" class="btn-get-started">Get Started</a> */}
            </div>
          </div>
        </div>
      </div>
      <div class="carousel slide">
        <div class="image-box1">
          <div className="slideimgg" style={{ backgroundImage: `url("${sliders[currentIndex]}")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", }}></div>
        </div>
      </div>

    </section>
  )
}

export default Hero;