import "../App.css";

export default function header() {
    return (
        <header id="header" class="header d-flex align-items-center">
            <div class="container-fluid  d-flex align-items-center justify-content-between">
                <div class="container h-50">
                    <div class="row h-50 justify-content-center align-items-center">
                        <div class="d-flex align-items-center justify-content-center ">
                            <div style={{ cursor: "pointer", marginRight: 0, marginTop: 10, marginBottom: 10 }} class="MenuMailSH">
                                <a href="tel:+919825214066">+91 98252 14066</a> &nbsp;&nbsp;
                            </div>
                            <span class="MenuMailSH"> &nbsp;|
                                &nbsp;
                            </span>
                            <a href="mailto:contact@axisdesigns.co.in" class="email MenuMailSH">contact@axisdesigns.co.in</a>
                        </div>
                        <div class="MenuDisM">
                            <center>
                                <a href="tel:+919825214066"><h5>+91 98252 14066</h5></a>

                                <a href="mailto:contact@axisdesigns.co.in" class="email"><h5>contact@axisdesigns.co.in</h5></a>
                            </center>
                        </div>
                        

                    </div>
                </div>
            </div>
        </header>
    )
}