import './App.css';
import { useEffect, useState } from 'react';
import Headers from './components/header';
import Hero from './components/hero';
import Contact from './components/contact';
import Constructions from './components/construction';
import Footer from './components/footer';
import Servicedetails from './components/service-details';
import {
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Projects from './components/projects';
import AllProjects from './components/all-project';
import AllServices from './components/all-services';
import Whatsapp from './components/whatsapp';
import { WebsiteApi } from './Config/API';
import { ThreeCircles } from 'react-loader-spinner';
import ContactUs from './components/contact-us';
import Menu from './components/menu';

function App() {
  const [sliders, setsliders] = useState([]);
  useEffect(() => {
    GetData();
  }, [])

  const GetData = () => {
    WebsiteApi.AllSlider()
      .then((response) => {
        response.data.data.map((datat) => {
          sliders.push(datat.image);
        });
        setsliders([...sliders])
      })
      .catch((error) => {

      });
  }

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const navigate = useNavigate();

  function Web() {
    return (
      <>
        {sliders.length !== 0 ? <div>
          <Headers />

          <Hero sliders={sliders} />
          <Contact />
          <Constructions />
          <Projects PId={0} />
          <Footer />
          <Whatsapp />
          <Menu />
          <div class="stickyDockUprLink" onClick={handleClick}>
            <center><i class="bi bi-arrow-up"></i></center>
          </div>
        </div > :
          <div className='loader'>
            <div className='center'>
              <ThreeCircles
                height="100"
                width="100"
                color="#ffffff"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
              />
            </div>
          </div>}
      </>
    )
  }

  return (
    <> <Routes>
      <Route
        path="/service"
        element={<Servicedetails />}
      />
      <Route
        path="/contact-us"
        element={<ContactUs />}
      />
      <Route
        path="/projects"
        element={<AllProjects />}
      />
      <Route
        path="/services"
        element={<AllServices />}
      />
      <Route
        path="/"

        element={<Web />}
      />
    </Routes>
    </>
  );
}

export default App;


