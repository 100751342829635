import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Projects from './projects';
import Footer from './footer';
import { WebsiteApi } from '../Config/API';
import { ThreeCircles } from 'react-loader-spinner'
import Menu from './menu';

function Servicedetails({ match }) {
    const [Services, setServices] = useState([])
    const [Categories, setCategories] = useState([])
    const [Service, setService] = useState()

    const { state } = useLocation();
    const { id } = state; // Read values passed on state
    const navigate = useNavigate();
    useEffect(() => {
        GetDataByServiceId(id)
        GetCategoryByServiceId(id)
        GetData()
    }, []);
    const GetDataByServiceId = (id) => {
        WebsiteApi.FindServices(id)
            .then((response) => {
                setService(response.data.data)
            })
            .catch((error) => { });
    }
    const GetData = () => {
        WebsiteApi.AllServices()
            .then((response) => {
                setServices(response.data.data)
            })
            .catch((error) => { });
    }
    const GetCategoryByServiceId = (service_id) => {
        WebsiteApi.AllServiceCategory(service_id)
            .then((response) => {
                setCategories(response.data.data)
            })
            .catch((error) => { });
    }
    if (Service != null && Services.length !== 0) {
        return (
            <div>
                <div class="breadcrumbs d-flex align-items-center" style={{ background: "#000000" }}>
                    <div class="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                        <h2>Service Details</h2>
                        <ol>
                            <li><a onClick={() => { navigate('/'); }}>Home</a></li>
                            <li>Service Details</li>
                        </ol>
                    </div>
                </div>
                <section id="service-details" class="service-details">
                    <div class="container" data-aos="fade-up" >
                        <div class="row">
                            <div class="col-lg-12" >
                                <div class="row">
                                    <div class="col-lg-6">
                                        <img src={Service.image} alt="" class="img-fluid services-img" style={{ width: "100%" }} />
                                    </div>
                                    <div class="col-lg-6">
                                        <h3>{Service.title}</h3>
                                        <p>{Service.description}</p>
                                        <ul>
                                            {Categories.map((data) => (
                                                <>
                                                    <li>{data.name}</li>
                                                </>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <Projects PId={id} />
                        <div class="row gy-4" >
                            <div class="d-flex" data-aos="fade-up" data-aos-delay="100" >
                                <div class="services-list">
                                    {Services.map((data) => (
                                        <>
                                            {data.id === id ? (
                                                <a class="active" style={{ cursor: "pointer" }}><div onClick={() => {
                                                    navigate('/service', { state: { id: data.id } }); window.location.reload(true);
                                                }}>{data.title}</div></a>
                                            ) :
                                                (
                                                    <a style={{ cursor: "pointer" }}><div onClick={() => { navigate('/service', { state: { id: data.id }, replace: true }); window.location.reload(true); }}>{data.title}</div></a>
                                                )
                                            }
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                <Footer />
                <Menu />
            </div >
        )
    }
    return (
        <div className='loader'>
            <div className='center'>
                <ThreeCircles
                    height="100"
                    width="100"
                    color="#ffffff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor=""
                    middleCircleColor=""
                />
            </div>
        </div>
    )
}

export default Servicedetails;