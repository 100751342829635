import { useEffect, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { WebsiteApi } from "../Config/API";

function Constructions() {
    const [services, setservices] = useState([])

    useEffect(() => {
        GetData()
    }, []);

    const GetData = () => {
        WebsiteApi.AllServices()
            .then((response) => {
                setservices(response.data.data)
            })
            .catch((error) => { });
    }

    const navigate = useNavigate();
    if (services.length !== 0 && services != null) {
        return (
            <>
                <section id="constructions services" class="constructions">
                    <div class="container" data-aos="fade-up">
                        <div class="section-header">
                            <h2>Services</h2>
                            <p>Choose your Need of Services</p>
                        </div>

                        <div class="row gy-4">
                            {services.slice(0, 3).map((service) => (
                                <div class="col-sm-4" onClick={() => { navigate('/service', { state: { id: service.id } }); }}>
                                    <div class="card">
                                        <img class="card-img-top" src={service.image} alt="Card image cap" />
                                        <div class="card-body">
                                            <div className="childbb">
                                                <h5 class="card-title">{service.title}</h5>
                                            </div>
                                            <p class="card-text">{service.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* <center><button className='projectbtn' onClick={() => { navigate('/services'); }}>View All Services &#8594;</button></center> */}
                    </div>

                </section>
            </>
        )
    }
    return (
        <div className='loader'>
            <div className='center'>
                <ThreeCircles
                    height="100"
                    width="100"
                    color="#ffffff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor=""
                    middleCircleColor=""
                />
            </div>
        </div>
    )
}

export default Constructions;