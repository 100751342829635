import React, { useEffect, useState } from 'react'
import { Modal, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import "../App.css";
import ImageGallery from 'react-image-gallery';
import { WebsiteApi } from "../Config/API";
import { ThreeCircles } from 'react-loader-spinner';

function Projects({ PId }) {
    const [Portfolio, setPortfolio] = useState([])
    const [CategoriesName, setCategoriesName] = useState("");
    const [PortfolioImages, setPortfolioImages] = useState([]);

    useEffect(() => {
        if (PId === 0) {
            GetData()
        } else {
            GetDataByServiceId(PId)
        }
    }, []);

    const GetData = () => {
        WebsiteApi.AllPortfolio()
            .then((response) => {
                setPortfolio(response.data.data)
            })
            .catch((error) => { });
    }
    const GetDataByServiceId = (id) => {
        WebsiteApi.AllPortfolioByServiceId(id)
            .then((response) => {
                setPortfolio(response.data.data)
            })
            .catch((error) => { });
    }
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const GetImageDataById = (id, categoryName) => {
        setPortfolioImages([]);
        setCategoriesName(categoryName);

        WebsiteApi.PortfolioImagesById(id)
            .then((response) => {
                setPortfolioImages([]);
                if (response.data.data.length !== 0) {

                    response.data.data.map((element) => {
                        // setCategoriesName(element.category.title);
                        PortfolioImages.push({
                            original: element.image,
                            thumbnail: element.image,
                        },);
                    });
                    setPortfolioImages([...PortfolioImages]);
                    console.log(PortfolioImages);
                    console.log(CategoriesName);

                    if (PortfolioImages.length !== 0) {
                        handleShow();
                    }
                }
            })
            .catch((error) => { });
    }

    if (Portfolio.length !== 0) {
        return (
            <div>
                <section id="projects" class="projects">
                    <div class="container" data-aos="fade-up">

                        {/* <video width="320" height="240" autoplay>
                    <source src="https://mdbcdn.b-cdn.net/img/video/forest.mp4" type="video/mp4" />
                    <source src="movie.ogg" type="video/ogg" />
                    Your browser does not support the video tag.
                </video> */}
                        <div class="section-header">
                            {PId === 0 ? <h2>Portfolio</h2> : <h2>Our Recent Works</h2>}
                            {PId === 0 ? <p>Our works</p> : ""}
                        </div>

                        <div class="row" >
                            {Portfolio.slice(0, 3).map((data) => (
                                <>
                                    {/* <div class="col-sm-4 portf-col ng-scope" onClick={() => GetImageDataById(data.id, data.service.title)} style={{ cursor: "pointer" }}>
                                       
                                        <div class="portfolio-item-img">
                                            <img src={data.image} alt="Luxurious mansion in Rublevo" />
                                        </div>
                                        <div class="portfolio-item-info hidden-xs">
                                            <div class="portfolio-item-title">
                                                <span class="merriw">{data.service.title}</span>
                                            </div>
                                            <div class="portfolio-item-params">
                                                <div class="portfolio-item-style">{data.description}</div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div class="col-sm-4 " data-aos="fade-up" data-aos-delay="100" onClick={() => GetImageDataById(data.id, data.service.title)} style={{ cursor: "pointer" }}>
                                        <div class="card-item">
                                            <div className="childb1project">
                                                <p className="text">
                                                    <span className="title">{data.service.title}</span>
                                                    <p className="description">{data.description}</p>
                                                </p>
                                            </div>
                                            <div class="card-bg" style={{ background: `url("${data.image}")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", width: "100%", }}></div>
                                            <div class="card-body">
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                        <br />
                        <br />
                        {PId === 0 ? <center><button className='projectbtn' onClick={() => { navigate('/projects'); }}>View All Projects &#8594;</button></center> : ""}
                    </div>
                </section >
                <Modal
                    show={show}
                    onHide={() => { setShow(false); setPortfolioImages([]); }}
                    //     size='xl'
                    // >
                    //     <Modal.Header
                    //         style={{ backgroundColor: "#171717", }}
                    //         color='#fffs'
                    //     >
                    //         <div class="container">
                    //             <div class="d-flex justify-content-lg-between">
                    //                 <div class="d-flex align-content-center"><h4>{CategoriesName}</h4></div>
                    //                 <div style={{ fontSize: 30, cursor: "pointer" }} onClick={() => { handleClose(); setPortfolioImages([]); }}> <i class="bi bi-x"></i>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </Modal.Header>
                    fullscreen
                >
                    <Modal.Header
                        style={{ backgroundColor: "#171717", color: "white" }}
                        color='#fffs'
                        closeButton
                    >
                        <div class="container">
                            <div class="d-flex justify-content-center">
                                <div class="d-flex align-content-center "><h4>{CategoriesName}</h4></div>
                            </div>
                        </div>
                        <div style={{ fontSize: 30, cursor: "pointer" }} onClick={() => { handleClose(); setPortfolioImages([]); }}> <i class="bi bi-x"></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body
                        style={{ backgroundColor: "black" }}
                    >
                        <ImageGallery items={PortfolioImages} useTranslate3D={true} />
                    </Modal.Body>
                </Modal>
            </div >

        )
    }
}

export default Projects;

const images = [
    {
        original: 'https://picsum.photos/id/1018/1000/600/',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        original: 'https://picsum.photos/id/1015/1000/600/',
        thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
        original: 'https://picsum.photos/id/1019/1000/600/',
        thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
];
class MyGallery extends React.Component {
    render() {
        return <ImageGallery items={images} useTranslate3D={true} />;
    }
}