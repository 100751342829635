import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo from "./img/logo.png";
import Fulllogo from "./img/logo2.png";

function Menu() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(!show);

    const navigate = useNavigate();
    return (
        <>
            {
                !show ? <i class="bi bi-list stickyDockNav" onClick={handleShow} style={{ margin: 0, cursor: "pointer", }} ></i>
                    : ""
            } {
                !show ?
                    <div onClick={handleShow} class="stickyDock" >
                        <img src={logo} class="sidemenuimg" style={{ width: "80%", marginLeft: 10, marginTop: 10, marginBottom: -10 }} />
                        <center>
                            <i class="bi bi-list" style={{ fontSize: 35, color: "black", margin: 0, cursor: "pointer" }} ></i>
                            <center style={{ color: "black", margin: 0, cursor: "pointer" }}>
                                Menu
                            </center>
                        </center>
                    </div> : <></>
            }
            <Modal show={show} onHide={handleClose} placement='start' fullscreen>
                <Modal.Header style={{ backgroundColor: "#000000", borderColor: "#000" }} closeVariant="white" color='#ffffff' closeButton >
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "black" }}>
                    <div class="container" >
                        <div class="row">
                            <center>
                                <img class="menu-img" src={Fulllogo} /><br />
                                <ul className='sidebarLinks'>
                                    <li><a href="#" onClick={handleShow}><h4>Home</h4></a></li>
                                    <li><a onClick={() => { navigate('/contact-us'); handleShow(); }} style={{ cursor: "pointer" }}><h4>Contact</h4></a></li>
                                    <li><a onClick={() => { navigate('/services'); handleShow(); }} style={{ cursor: "pointer" }}><h4>Services</h4></a></li>
                                    <li><a onClick={() => { navigate('/projects'); handleShow(); }} style={{ cursor: "pointer" }}><h4>Portfolio</h4></a></li>
                                </ul>
                            </center>
                            <div class="mainfooster">
                                <center>
                                    <div class=" d-flex align-items-center justify-content-center">
                                        <a href="https://www.facebook.com/AxisDesignsBhuj?mibextid=LQQJ4d" class="d-flex align-items-center justify-content-center" style={{ margin: 7 }} className="socialIconSide" target="_blank" > <i class="bi bi-facebook"></i></a>
                                        <a href="https://instagram.com/axis.india?igshid=YmMyMTA2M2Y=" class="d-flex align-items-center justify-content-center" style={{ margin: 7 }} className="socialIconSide" target="_blank"><i class="bi bi-instagram"></i></a>
                                        <a href="https://www.linkedin.com/in/axisdesigns/" class="d-flex align-items-center justify-content-center" style={{ margin: 7 }} className="socialIconSide" target="_blank"><i class="bi bi-linkedin"></i></a>
                                        <a href="https://pin.it/6n7K8Gi" class="d-flex align-items-center justify-content-center" style={{ margin: 7 }} className="socialIconSide" target="_blank"><i class="bi bi-pinterest"></i></a>
                                    </div>
                                    <strong>Timing:</strong> 10:30 to 7:00 pm<br />
                                    <strong>Phone:</strong> <a href="tel:+919825214066">+91 98252 14066</a><br />
                                </center>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Menu